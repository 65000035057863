import React, { useEffect, useState } from 'react';
import { IoIosHeartEmpty, IoMdHeart } from "react-icons/io";
import { VscComment } from "react-icons/vsc";
import { MdDelete } from "react-icons/md";
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';

const PostCard = ({ blog, userId, deletePost, openDeleteMsg, setOpenDeleteMsg }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [metaData, setMetaData] = useState(null);
  const navigate = useNavigate();

  // EXTRACT URLS FROM TEXT
  const extractUrls = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text ? text.match(urlRegex) : null;
  };

  const url = extractUrls(blog.post);
  const urldecode = decodeURIComponent(url);
  
  useEffect(() => {
    if (url && url.length > 0) {
      const fetchMetaData = async () => {
        try {
          const requestUrl = `${process.env.REACT_APP_API_URL}/slugcity/get/metadata/${encodeURIComponent(url)}`;
          const res = await fetch(requestUrl);
          const data = await res.json();
          setMetaData(data);
        } catch (error) {
          console.error('Error fetching metadata:', error);
        }
      };
      fetchMetaData();
    }
  }, [urldecode]);

  return (
    <div onClick={() => navigate(`/userpostdetail/${blog && blog.id}`)} className='flex gap-4 w-[100%] mt-0 border-b-[1px] border-neutral-700 p-4 hover:bg-white/5'>
      <section className='mr-1'>
        <div className='bg-neutral-200 rounded-full w-12 h-12'></div>
      </section>
      <section className='w-full'>
        <div className='w-full font-semibold flex justify-between'>
          <span>
            <Link
              to={`/userprofile/${blog && blog.user_id}`}
              className='hover:text-red-500 w-full'
              onClick={(e) => {
                e.stopPropagation(); // Prevent the click event from bubbling up to the Link
              }}
            >
              {blog && blog.username}
            </Link>
          </span>
          <div className='flex items-center gap-4'>
            <span className='text-xs font-medium'>
              {moment(blog && blog.date).fromNow()}
            </span>
            <span>
              {blog.user_id === userId && (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation(); // Prevent the click event from bubbling up to the Link
                    setOpenDeleteMsg({ status: true, id: blog.id });
                  }}
                  className='text-red-500 text-lg p-1 w-fit border border-neutral-700 flex z-30 hover:bg-black/10'
                >
                  <MdDelete />
                </button>
              )}
            </span>
          </div>
        </div>
        <div className='my-0 text-sm text-gray-300 whitespace-pre-wrap'>
          {blog && (isExpanded ? blog.post : `${blog.post.substring(0, 100)}`)}
          {blog && blog.post.length > 100 && (
            <span className='text-gray-100 font-semibold'> ...read more</span>
          )}
          {metaData && (
            <>
            <Link
              to={metaData.url}
              target="_blank"
              rel="noreferrer"
              className='text-neutral-200 hover:text-white '
              onClick={e => e.stopPropagation()}
            >
              <div className='mt-2 border border-neutral-700 p-1 hover:bg-white/10'>
                <img src={metaData.image} alt={metaData.title} className='w-full h-48 object-cover rounded-md' />
                <h4 className='text-lg'>{metaData.title}</h4>
                <p className='text-gray-400'>{metaData.description}</p>
              </div>
            </Link>
      
            </>
          )}
        </div>
        <div className='mt-2 text-xl flex gap-2'>
          <IoIosHeartEmpty />
          <span className='text-red-500'><IoMdHeart /></span>
          <span><VscComment /></span>
        </div>
      </section>
    </div>
  );
};

export default PostCard;