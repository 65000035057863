import React, { useState } from 'react';

const AddUserPostForm = ({ openUserPostForm, setOpenUserPostForm, postUserBlog, setUserPost, userPost }) => {
  const [charCount, setCharCount] = useState(0);
  const CHARACTER_LIMIT = 300;

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (value.length <= CHARACTER_LIMIT) {
      setUserPost(value);
      setCharCount(value.length);
    }
  };

  return (
    <div onClick={() => setOpenUserPostForm(!openUserPostForm)} className='flex items-center justify-center w-full z-30 h-screen bg-white/10 backdrop-blur-sm fixed'>
      <form onClick={(e) => e.stopPropagation()} className='flex flex-col items-center justify-center xl:w-[650px] w-full xl:h-[360px] h-full xl:p-10 px-3 bg-[#282a2e] rounded-xl' action="">
        <textarea
          onChange={handleInputChange}
          value={userPost}
          className='xl:w-[550px] w-[100%]   text-white p-2'
          name=""
          id=""
          cols="30"
          rows="6"
        ></textarea>
        <div className=' xl:w-[550px] w-[100%] flex justify-end'>{charCount}/{CHARACTER_LIMIT}</div>
        <button onClick={postUserBlog} className='w-[200px] rounded-sm bg-red-500 font-semibold text-lg text-white px-4 py-2 mt-3'>Post</button>
      </form>
    </div>
  );
};

export default AddUserPostForm;