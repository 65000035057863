import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import PostCard from "../PostCard/PostCard";
import { FaArrowLeft } from "react-icons/fa";

const UserProfile = () => {
  // Scroll to top when component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // GOING BACK ARROW FUNCTION
  const navigate = useNavigate();

  const handleBackClick = () => {
    if (window.history.length > 2) {
      navigate(-1);
    } else {
      navigate("/");
    }
  };

  return (
    <div className="flex w-[66%] min-h-screen mx-auto px-2 ">
      <section className="flex flex-col w-[60%] border-neutral-700 border-r-[1px]  pr-2">
        <div className="flex items-center">
          {" "}
          <FaArrowLeft
            className="text-4xl font-thin cursor-pointer my-2 mx-2 rounded-full hover:bg-white/10 p-2"
            onClick={handleBackClick}
          />{" "}
          back
        </div>

      </section>
      <section className="w-[36%] h-full sticky top-28 p-4">
        <div className="flex gap-4 items-center relative">
          <div className="bg-black rounded-full w-24 h-24"></div>
          <p className="font-semibold text-lg">UserName</p>
        </div>
        <div className=" p-5 font-semibold">follower:0 follows:0</div>
        <button className="w-full rounded-md bg-red-500 text-white font-medium mt-6 py-2">
          follow
        </button>
      </section>
    </div>
  );
};

export default UserProfile;
