import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Hero from './Hero'
import PostCard from '../PostCard/PostCard'

const Home = ({ reload, userId, deletePost, openDeleteMsg, setOpenDeleteMsg,loggedIn, logoutUser }) => {
  const menu = [
    { name: "Home", link: "/" },
  ];

  const userMenu = [
    { name: "Profile", link: "/profile" },
    { name: "Settings", link: "/settings" },
    { name: "Logout", link: "/", fnc: logoutUser },
  ];

  const [userBlogs, setUserBlogs] = useState([])
  
  useEffect(() => {
    const fetchUserBlogs = async () => {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/slugcity/get/alluserblogposts`)
      const data = await res.json()
      setUserBlogs(data)
    }
    fetchUserBlogs()
  }, [reload])

  return (
    <div className='w-full flex justify-center min-h-screen'>
      <section className=' xl:flex flex-col hidden    w-[230px] min-h-screen border-l-[1px] border-neutral-700 bg-[#2C2D31]'>
        <div className='flex flex-col sticky top-32'>
        
          {menu &&
              menu.map((item, index) => (
                <Link
                  key={index}
                  to={item.link}
                  className="text-base pl-4 py-2 w-full hover:text-sky-400 duration-100 border-dotted border-b-[1px] border-b-gray-600"
                >
                  {item.name}
                </Link>
              ))}
  
            {loggedIn &&
              <>
                       
              {userMenu.map((item, index) => (
                <Link
                  key={index}
                  to={item.link}
                  onClick={
                    item.fnc
                      ? (e) => {
                        e.preventDefault();
                        item.fnc();
                      }
                      : null
                  }
                  className="text-base pl-4 py-2 w-full hover:text-sky-400 duration-100 border-dotted border-b-[1px] border-b-gray-600"
                >
                  {item.name}
                </Link>
              ))}
            </>
             }
      </div>
      </section>
      <section className='xl:w-[38%] w-full border-l-[1px] border-neutral-700  border-r-[1px] min-h-full '>
        {userBlogs && userBlogs.map((blog) => (
          <PostCard key={blog.id}
            blog={blog}
            userId={userId}
            deletePost={deletePost}
            openDeleteMsg={openDeleteMsg}
            setOpenDeleteMsg={setOpenDeleteMsg}
          />
        ))}
      </section>
      <section className='hidden xl:block w-[230px] min-h-screen border-r-[1px] border-neutral-700 bg-[#2C2D31] '></section>
</div>
  )
}

export default Home