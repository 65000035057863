import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import moment from "moment";
import { IoIosHeartEmpty } from "react-icons/io";
import { IoMdHeart } from "react-icons/io";
import { VscComment } from "react-icons/vsc";
import { FaArrowLeft } from "react-icons/fa";

const UserBlogDetail = () => {
  const { blog_id } = useParams();
  const [userBlogDetail, setUserBlogDetail] = useState("");

  // Scroll to top when component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //FETCH DETAIL BLOG
  useEffect(() => {
    const fetchUserBlogDetail = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/slugcity/get/userblogpost/${blog_id}`
        );
        const data = await response.json();
        setUserBlogDetail(data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchUserBlogDetail();
  }, [blog_id]);

  // GOING BACK ARROW FUNCTION
  const navigate = useNavigate();

  const handleBackClick = () => {
    if (window.history.length > 2) {
      navigate(-1);
    } else {
      navigate("/");
    }
  };

// META CARD DATA
  const [metaData, setMetaData] = useState(null);
 
  const extractUrls = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text ? text.match(urlRegex) : null;
  };

  const url = extractUrls(userBlogDetail && userBlogDetail[0].post);
  console.log("URL", url);
  const urldecode = decodeURIComponent(url);

  console.log({ "BLOG": urldecode && urldecode });
  
  useEffect(() => {
    if (url && url.length > 0) {
      const fetchMetaData = async () => {
        try {
          const requestUrl = `${process.env.REACT_APP_API_URL}/slugcity/get/metadata/${encodeURIComponent(url)}`;
          const res = await fetch(requestUrl);
          const data = await res.json();
          setMetaData(data);
        } catch (error) {
          console.error('Error fetching metadata:', error);
        }
      };
      fetchMetaData();
    }
  }, [urldecode]);
  console.log({ "m": userBlogDetail && userBlogDetail[0].post })
  console.log({"metaData":metaData && metaData})
  return (
    <div className="w-full  min-h-screen flex justify-center">
      <section className="xl:w-[38%] w-full border-neutral-700 border-l-[1px] border-r-[1px]">
        <div className="flex items-center">
          {" "}
          <FaArrowLeft
            className="text-4xl cursor-pointer my-2 mx-2 rounded-full hover:bg-white/10 p-2"
            onClick={handleBackClick}
          />{" "}
          blog
        </div>
        {userBlogDetail &&
          userBlogDetail.map((blog, index) => (
            <div key={blog.id} className="flex gap-4 w-[100%] mt-0 border-neutral-700 border-b-[1px]  p-4 ">
              <section className="w-full">
                <div className="w-full flex items-center gap-2 mb-4">
                  <div className="bg-neutral-200  rounded-full w-12 h-12"></div>
                  <p className=" font-semibold flex justify-between">
                    <Link
                      to={`/userprofile/${blog && blog.user_id}`}
                      className="hover:text-rose-500"
                    >
                      {blog && blog.username}
                    </Link>
                  </p>
                </div>

                <div className="my-2 text-sm text-gray-300 whitespace-pre-wrap">
                  {blog && blog.post}
                </div>
                {metaData && (
            <>
            <Link
              to={metaData.url}
              target="_blank"
              rel="noreferrer"
              className='text-neutral-200 hover:text-white '
              onClick={e => e.stopPropagation()}
            >
              <div className='mt-2 border border-neutral-700 p-1 hover:bg-white/10'>
                <img src={metaData.image} alt={metaData.title} className='w-full h-48 object-cover rounded-md' />
                <h4 className='text-lg'>{metaData.title}</h4>
                <p className='text-gray-400'>{metaData.description}</p>
              </div>
            </Link>
      
            </>
          )}
                <span className=" text-gray-400 ">
                  {moment(blog && blog.date).format("LLLL")}
                </span>
                <div className="mt-2 text-xl flex gap-2">
                  <IoIosHeartEmpty />
                  <span className="text-red-500">
                    <IoMdHeart />
                  </span>
                  <span>
                    <VscComment />
                  </span>
                </div>
                
              </section>

            </div>
          ))}
      </section>
    </div>
  );
};

export default UserBlogDetail;
