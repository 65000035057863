import React from 'react'

const DeleteUserPost = ({ openDeleteMsg, setOpenDeleteMsg,deletePost}) => {
  return (
    <div  className='flex items-center justify-center w-full z-30 h-screen bg-black/60 backdrop-blur-sm fixed'>
    <form onClick={(e) => e.stopPropagation()} className='flex flex-col gap-4 items-center justify-center xl:w-[650px] w-full xl:h-[400px] h-full xl:p-10 px-3 bg-[#282a2e] rounded-md' action="">
              <p className='font-semibold text-lg'>do u wanna delete your post?</p>
              <button onClick={(e)=> deletePost(e,openDeleteMsg.id)} className='text-red-500 font-semibold border border-neutral-600 rounded-full px-8 py-2 hover:bg-red-500 hover:text-white'>Delete</button>
              <button onClick={() => setOpenDeleteMsg(false) } className='font-semibold border border-neutral-600 rounded-full px-8 py-2 hover:bg-white/10'>Cancle</button>
    </form>
  </div>
  )
}

export default DeleteUserPost