import { useEffect, useState } from "react";
import Navigation from "./Components/Navigation";
import Home from "./Components/Home/Home";
import { Route, Routes,useNavigate } from "react-router-dom";
import UserProfile from './Components/UserProfile/UserProfile'
import Login from './Components/Login/Login'
import AddUserPostButton from './Components/Modals/AddUserPostButton'
import AddUserPostForm from "./Components/Modals/AddUserPostForm";
import UserBlogDetail from "./Components/UserBlogDetail/UserBlogDetail";
import HashtagInput from "./Components/Hashtag/HashtagInput";
import AskForDelete from "./Components/Modals/DeleteUserPost";

function App() {
  const [openUserPostForm, setOpenUserPostForm] = useState(false);
  const [openDeleteMsg, setOpenDeleteMsg] = useState(false);
  const [message, setMessage] = useState("");
  const [reload, setReload] = useState(false);
  const [inputFields, setInputFields] = useState({});
  //login register hooks
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
 
  const [loginEmail, setLoginEmail] = useState("");
  const [username, setUsername] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
// USER POST AND COMMENTS
  const [userPost, setUserPost] = useState("");
  const userId = user && user.id;
 
  const handleChange = (e) => {
    e.preventDefault();
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
  };
  


  // useNavigate for redirecting after login
  const navigate = useNavigate();

  //get token and fetch userdata
  useEffect(() => {
    const fetchUserData = async () => {
      console.log("fetching user data");
      const token = localStorage.getItem("slugcity_token");
      setToken(token);
  
      if (!token) {
        return;
      }

      try {
        const res = await fetch(
          `${process.env.REACT_APP_API_URL}/slugpage/get/userdata`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await res.json();
        
        if (res.status === 200) {
          setUser(data.user);
          setLoggedIn(true);
          //navigate('/dashboard?view=home');
        } else if (res.status === 500) {
          // Token is expired or invalid
          localStorage.removeItem("slugcity_token");
          setUser(null);
          setLoggedIn(false);
        }
      } catch (error) {
        console.log({ "fetch user data error": error });
      }
    };

    fetchUserData();
  }, [token]);
  
  //register user
  const registerUser = async (e) => {
    e.preventDefault();

    try {
        const res = await fetch(
            `${process.env.REACT_APP_API_URL}/slugpage/post/register`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    username: username,
                    email: loginEmail,
                    password: loginPassword,
                }),
            }
        );
      const data = await res.json();
 
        if (res.status === 201) {
            localStorage.setItem("slugcity_token", data.token);
            setToken(data.token);
            setUser(data.user);
            setLoggedIn(true);
            navigate("/");
            setUsername("");
            setLoginEmail("");
           setLoginPassword("");
        }
    } catch (error) {
        console.log({ "register user error": error });
    }
};

  //login user
  const loginUser = async (e) => {
    e.preventDefault();
    console.log("logging in user");
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/slugpage/post/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: loginEmail, password: loginPassword }),
        }
      );
      const data = await res.json();
     
      if (res.status === 200) {
        localStorage.setItem("slugcity_token", data.token);
        setToken(data.token);
        setUser(data.user);
        setLoggedIn(true);
        navigate("/");
      }
    } catch (error) {
      console.log({ "login user error": error });
    }
  };
  //logout user
  const logoutUser = async () => {
    console.log("logging out user");
    localStorage.removeItem("slugcity_token");
    setToken(null);
    setUser(null);
    setLoggedIn(false);
    setLoginEmail(null);
    setLoginPassword(null);
  };

  //forgot password
  const forgotPassword = async (e) => {
    e.preventDefault();
    console.log("forgot password");
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/slugpage/post/forgotpwd`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: loginEmail }),
        }
      );
      const data = await res.json();
      console.log(data);
      setLoginEmail(""); //reset email
      if (res.status === 200) {
        setMessage(data.message);
      }
    } catch (error) {
      console.log({ "forgot password error": error });
    }
  };

  
  // POST user blog
  const postUserBlog = async (e) => {
    e.preventDefault();
    console.log("posting user blog");
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/slugcity/post/userblogpost`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ post: userPost, user_id: userId }),
        }
      );
      const data = await res.json();
      
      setUserPost("");
      setOpenUserPostForm(false);
      setReload(!reload);
    } catch (error) {
      console.log({
        "post user blog error": error
      });
    }
  };

  const deletePost = async (e, id) => {
    e.preventDefault();
    console.log({ "deleting post": id, "user_id": userId });
  
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/slugcity/delete/userblogpost/${id}`,
        {
          method: "DELETE",
          body: JSON.stringify({ user_id: userId }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (!res.ok) {
        const errorData = await res.json();
        console.log({ "delete post error": errorData });
        throw new Error(`Error: ${res.status} ${res.statusText}`);
      }
  
      const data = await res.json();
      console.log({ "delete post response": data });
  
      setReload(!reload);
      setOpenDeleteMsg(false);
    } catch (error) {
      console.log({ "delete post error": error.message });
    }
  };

  return (
    <div className="w-[100%]">
      {loggedIn ? <AddUserPostButton setOpenUserPostForm={setOpenUserPostForm} openUserPostForm={openUserPostForm } /> : null}
      {openUserPostForm ? <AddUserPostForm setOpenUserPostForm={setOpenUserPostForm} openUserPostForm={openUserPostForm} postUserBlog={postUserBlog} setUserPost={setUserPost} userPost={userPost} /> : null}
      {openDeleteMsg ? <AskForDelete openDeleteMsg={openDeleteMsg } setOpenDeleteMsg={setOpenDeleteMsg} deletePost={deletePost} /> : null}
      <Navigation loggedIn={ loggedIn} user={user} logoutUser={logoutUser}  />
      <Routes>
        <Route path="/" element={<Home
          reload={reload}
          userId={userId}
          deletePost={deletePost}
          openDeleteMsg={openDeleteMsg}
          setOpenDeleteMsg={setOpenDeleteMsg}
          loggedIn={loggedIn}
          logoutUser={logoutUser}
        />} />
        <Route path="/hash" element={<HashtagInput reload={reload} />} />
        <Route path="/userpostdetail/:blog_id" element={<UserBlogDetail />} />
        <Route path="/profile" element={<UserProfile />} />
        <Route path="/login"
          element={<Login
            registerUser={registerUser}
            loginUser={loginUser}
            loginEmail={loginEmail}
            setLoginEmail={setLoginEmail}
            setLoginPassword={setLoginPassword}
            username={username}
            loginPassword={loginPassword}
            setUsername={setUsername}
          />} />
      </Routes>
    </div>
  );
}

export default App;
